import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";

import { AppDrawerPage } from "../common/app-drawer";
import { NotFoundPage, StartPage } from "../common/pages";
import { IntegrationSettingsPage } from "../admin/integration";
import { TransactionSettingsPage, RiskLevelsTab } from "../admin/transaction";
import { SystemsListPage, SystemFormPage } from "../admin/transaction/systems";
import { BusynessProcessesListPage, BusynessProcessFormPage } from "../admin/transaction/busyness-processes"
import { GroupFormPage, SystemGroupsListPage } from "../admin/transaction/system-groups";
import { UserFormPage, UsersListPage } from "../admin/users";
import { LocksPage } from "../admin/locks";
import { AdminPage } from "../admin/admin-page";
import AccessRulesPage from "../access-rules/access-rules-page.jsx";
import { SignInPage, ForbiddenPage } from "../auth/pages";
import { RequireAuth } from "../auth/require-auth";
import { RequireAction } from "../auth/require-action";
import { FunctionListPage, FunctionFormPage } from "../access-rules/functions"
import { RoleListPage, RoleFormPage } from "../critical-rules/critical-roles"
import { ProfileListPage, ProfileFormPage } from "../critical-rules/critical-profiles"
import { RiskListPage, RiskFormPage } from "../access-rules/risks"
import { MatrixHeadersListPage, MatrixHeaderFormPage } from "../access-rules/matrix-headers";
import { RoleLevelReportPage, UserLevelReportPage, UserLevelBusynessReportPage } from "../sod-reports";
import { fetchUser } from "../../reducers/authSlice";
import IntegrationSchedulePage from "../admin/integration/integration-schedule-page.jsx";
import CriticalRulesPage from "../critical-rules/critical-rules-page.jsx";
import { RoleLevelModelingPage, UserLevelModelingPage } from "../reports-modeling";

import "@fontsource/mulish/400.css";
import "@fontsource/mulish/500.css";
import "@fontsource/mulish/600.css";
import "@fontsource/mulish/700.css";
import "@fontsource/mulish/800.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/manrope/500.css";
import "@fontsource/manrope/700.css";
import "@fontsource/outfit/400.css";
import "@fontsource/outfit/500.css";
import "segoe-fonts/segoe-fonts.css";
import "react-date-range/dist/styles.css";
import 'react-date-range/dist/theme/default.css';
import "./style.less";


const App = () => {
    const dispatch = useDispatch();
    const signedIn = useSelector(state => state.auth.signedIn);

    useEffect(() => {
        if (signedIn) {
            dispatch(fetchUser());
        }
    }, [signedIn, dispatch]);
    
    return (
        <Box className="app">
            <Routes>
                <Route
                    path="/sign-in"
                    element={<SignInPage />}
                />
                <Route
                    path="/forbidden"
                    element={<ForbiddenPage />}
                />
                <Route
                    path="/"
                    element={<RequireAuth />}
                >
                    <Route
                        path=""
                        element={<AppDrawerPage />}
                    >
                        <Route
                            path=""
                            index
                            element={<StartPage />}
                        />

                        {/* sod_reports */}
                        <Route
                            path="sod-reports/user-level/:variantName?"
                            element={<RequireAction actionId="user_level_report" />}
                        >
                            <Route
                                path=""
                                element={<UserLevelReportPage />}
                            />
                        </Route>

                        <Route
                            path="sod-reports/role-level/:variantName?"
                            element={<RequireAction actionId="role_level_report" />}
                        >
                            <Route
                                path=""
                                element={<RoleLevelReportPage />}
                            />
                        </Route>

                        {/* sod busyness reports */}
                         <Route
                            path="sod-reports/summary-report-users/:rowsForCalc?"
                            element={<RequireAction actionId="summary_report_users" />}
                        >
                            <Route
                                path=""
                                element={<UserLevelBusynessReportPage />}
                            />
                        </Route>

                        {/* reports_modeling */}
                        <Route
                            path="reports-modeling/role-level/:variantName?"
                            element={<RequireAction actionId="role_level_modeling" />}
                        >
                            <Route
                                path=""
                                element={<RoleLevelModelingPage />}
                            />
                        </Route>

                        <Route
                            path="reports-modeling/user-level/:variantName?"
                            element={<RequireAction actionId="user_level_modeling" />}
                        >
                            <Route
                                path=""
                                element={<UserLevelModelingPage />}
                            />
                        </Route>

                        {/* sod_matrix_maintenance */}
                        <Route
                            path="sod-maintenance/access-rules"
                            element={<RequireAction actionId="access_rules" />}
                        >
                            <Route
                                path="functions/form/:functionId?"
                                element={<FunctionFormPage />}
                            />
                            <Route
                                path="risks/form/:riskId?"
                                element={<RiskFormPage />}
                            />
                            <Route
                                path="matrix-headers/form/:matrixHeaderId?"
                                element={<MatrixHeaderFormPage />}
                            />
                            <Route
                                path=""
                                element={<AccessRulesPage />}
                            >
                                <Route
                                    path="functions"
                                    element={<FunctionListPage />}
                                />
                                <Route
                                    path="risks"
                                    element={<RiskListPage />}
                                />
                                <Route
                                    path="matrix-headers"
                                    element={<MatrixHeadersListPage />}
                                />
                            </Route>
                        </Route>

                        <Route
                            path="sod-maintenance/critical-rules"
                            element={<RequireAction actionId="critical_rules" />}
                        >
                            <Route
                                path="critical-roles/form/:name?/:system?"
                                element={<RoleFormPage />}
                            />
                            <Route
                                path="critical-profiles/form/:name?/:system?"
                                element={<ProfileFormPage />}
                            />
                            <Route
                                path=""
                                element={<CriticalRulesPage />}
                            >
                                <Route
                                    path="critical-roles"
                                    element={<RoleListPage />}
                                />
                                <Route
                                    path="critical-profiles"
                                    element={<ProfileListPage />}
                                />
                            </Route>
                        </Route>

                        {/* integrations */}
                        <Route
                            path="integration"
                            element={<RequireAction actionId="integrations" />}
                        >
                            <Route
                                path=":system?/:systemType?/:scheduleType?/schedule/:taskId?"
                                element={<IntegrationSchedulePage />}
                            />
                            <Route
                                path=""
                                element={<IntegrationSettingsPage />}
                            />
                        </Route>

                        {/* users */}
                        <Route
                            path="users"
                            element={<RequireAction actionId="users" />}
                        >
                            <Route
                                path="form/:username?"
                                element={<UserFormPage />}
                            />
                            <Route
                                path=""
                                element={<UsersListPage />}
                            />
                        </Route>

                        {/* settings */}
                        <Route
                            path="settings/systems"
                            element={<RequireAction actionId="systems_settings" />}
                        >
                            <Route
                                path="systems/form/:systemId?"
                                element={<SystemFormPage />}
                            />
                            <Route
                                path="system-groups/form/:groupId?"
                                element={<GroupFormPage />}
                            />
                            <Route
                                path=""
                                element={<TransactionSettingsPage />}
                            >
                                <Route
                                    path="systems"
                                    element={<SystemsListPage />}
                                />
                                <Route
                                    path="system-groups"
                                    element={<SystemGroupsListPage />}
                                />
                            </Route>
                        </Route>
                        
                        <Route
                            path="settings/risk-levels"
                            element={<RequireAction actionId="risk_levels_settings" />}
                        >
                            <Route
                                path=""
                                element={<RiskLevelsTab />}
                            />
                        </Route>

                        {/* bp */}
                        <Route
                            path="settings/busyness-processes"
                            element={<RequireAction actionId="business_process_maintenance" />}
                        >
                            
                            <Route
                                path="form/:busynessProcessId?"
                                element={<BusynessProcessFormPage />}
                            />

                            <Route
                                path=""
                                element={<BusynessProcessesListPage />}
                            />
                        </Route>

                        <Route
                            path="settings/locks"
                            element={<RequireAction actionId="locks_settings" />}
                        >
                            <Route
                                path=""
                                element={<LocksPage />}
                            />
                        </Route>

                        <Route
                            path="settings/admin"
                            element={<RequireAction actionId="admin_settings" />}
                        >
                            <Route
                                path=""
                                element={<AdminPage />}
                            />
                        </Route>
                        
                    </Route>
                </Route>
                
                <Route
                    path="*"
                    element={<NotFoundPage />}
                />
            </Routes>
        </Box>
    );
};

export default App;
